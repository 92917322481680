<template>
    <v-tabs
        grow
        id="charts-tabs"
        v-model="tab">
            <v-tab href="#charts" class="mt-3">
                <v-icon left>mdi-hospital-box</v-icon>
                {{ $t('health') }}
            </v-tab>
            <v-tab href="#weather" class="mt-3">
                <v-icon left>mdi-weather-partly-cloudy</v-icon>
                {{ $t('weather') }}
            </v-tab>

        <!-- GAUGES -->
        <v-tabs-items v-model="tab">
            <v-tab-item value="charts">
                <Charts
                    :id="id"
                    :version="version"
                    :customGauges="customGauges"></Charts>
            </v-tab-item>

            <v-tab-item value="weather">
                <Weather
                    :id="id"
                    :period="60"
                    :toDate="now"></Weather>
            </v-tab-item>

        </v-tabs-items>
    </v-tabs>
</template>


<script>
const Charts = () => import(
    /* webpackChunkName: "system-charts" */ '@/components/Charts.vue'
)
const Weather = () => import(
    /* webpackChunkName: "system-weather" */ '@/components/Weather.vue'
)

const moment = require('moment-timezone')

export default {
    props: {
        id: Number,
        version: Number,
        customGauges: {
            default() {
                return {}
            },
            type: Object,
        },
        selectedTab: {
            default() {
                return 'charts'
            },
            type: String,
        },
    },

    components: {
        Charts,
        Weather,
    },

    computed: {
        tab: {
            set(view) {
                if (this.tab !== view) {
                    const newPath = `/system/${this.id}/${view}`
                    this.$router.push(newPath)
                }
            },
            get() {
                return this.selectedTab || 'charts'
            },
        },

        now() {
            return moment().toDate()
        },
    },

    watch: {
        customGauges() {
        },
    },
}
</script>

<style>
    #charts-tabs .v-tabs-bar {
        position: fixed;
        top: 4rem;
        width: 100%;
        z-index: 2;
        background-color: #fff;
    }
</style>
